import '../assets/css/footer.css';
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-primary-gradient">
        <div className="container py-4">
            <div className="text-muted d-flex justify-content-between align-items-center pt-3">
                <p className="mb-0" style={{color:'#ffffff50'}}>Александр Перевалов, Владислав Раскошинский | Все права защищены © 2024</p>
                <ul className="list-inline mb-0">
                </ul>
            </div>
        </div>
    </footer>
  );
}

export default Footer;
