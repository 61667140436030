import "../assets/css/index.css";

import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Index = () => {
  return (
    <div class="container">
        <h1 className="main-heading">
            💡 Новейший подход к анализу визуального контента
        </h1>
        <div className="d-flex justify-content-center my-4">
            <a type="button" className="book-button" href="https://forms.yandex.com/u/6759e99290fa7baf6024c851/">Записаться в Waitlist</a>
        </div>
        <h2 id="useCases" className="secondary-heading">
            Примеры использования
        </h2>

        <div class="row">
            <div class="col-md-4">
                <div class="card shadow-sm">
                    <img src="/static/infographics.jpg" class="card-img-top" alt="Placeholder image with dimensions 300x200px" />
                    <h5 class="card-title">Диалог с инфографикой</h5>
                    <p class="card-text">Анализируйте инфографику и сложные таблицы в режиме чата текстом или голосом</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card shadow-sm">
                    <img src="/static/understanding.jpg" class="card-img-top" alt="Placeholder image with dimensions 300x200px" />
                    <h5 class="card-title">Понимание видеоконтента</h5>
                    <p class="card-text">Суммаризация, генерация описаний и ответы на вопросы по видео со звуком и без</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card shadow-sm">
                    <img src="/static/metadata.jpg" class="card-img-top" alt="Placeholder image with dimensions 300x200px" />
                    <h5 class="card-title">Генерация метаданных</h5>
                    <p class="card-text">Автоматическая генерация хэштегов, описаний, категории видео и т.д.</p>
                </div>
            </div>
        </div>

        <h2 id="howItWorks" className="secondary-heading">
            Как это работает
        </h2>

        <div className='card-course'>
            <Accordion className="accordionMui" sx={{'& .MuiTypography-root' :{   //use sx and select related class
              fontSize:'20px'             // change its style
      }}}>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon sx={{ color: 'white' }} />}
                aria-controls="panel2-content"
                id="panel2-header"
                >
                <Typography>1. Мультимодальный анализ</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Мы используем передовые методы машинного обучения для анализа текста, изображений и звука. Наша платформа понимает контент в разных форматах и может обрабатывать мультимодальные запросы.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="accordionMui" sx={{'& .MuiTypography-root' :{   //use sx and select related class
              fontSize:'20px'             // change its style
      }}}>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon  sx={{ color: 'white' }}/>}
                aria-controls="panel3-content"
                id="panel3-header"
                >
                <Typography>2. Векторное представление контента и построение графа знаний</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Мы преобразуем контент в векторное представление и строим граф знаний, который позволяет нашей платформе понимать связи между разными элементами визуального контента.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="accordionMui" sx={{'& .MuiTypography-root' :{   //use sx and select related class
              fontSize:'20px'             // change its style
      }}}>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon  sx={{ color: 'white' }}/>}
                aria-controls="panel4-content"
                id="panel4-header"
                >
                <Typography>3. Декодирование информации на основе задачи пользователя</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Решение задачи пользователя осуществляется путем декодирования информации из графа знаний и векторного представления контента. Наша платформа предоставляет ответы на вопросы, генерирует описания и метаданные, а также выполняет другие задачи.
                </Typography>
                </AccordionDetails>
            </Accordion>
        </div>

        <h2 id="awards" className="secondary-heading">
            Наши достижения
        </h2>

          <div class="row" >
          <div class="col-md-1">

</div>
              <div class="col-md-5">
              <div class="card shadow-sm">
                    <img src="/static/moex.jpg" class="card-img-top" />
                    <h5 class="card-title">1-е место на хакатоне HackGenesis от Мосбиржи</h5>
                    <p class="card-text">Провели исследование и реализовали максимально мультимодальный RAG-подход во взаимодействии с LLM-моделями в области финансовой информации.</p>
                    <a href="https://youtu.be/tF_e29Xyq_4?si=sZ8RsMT4ts79Kn47" target="_blank" class="btn">Смотреть доклад</a>
                </div>
              </div>

              <div class="col-md-5">
              <div class="card shadow-sm">
                    <img src="/static/aij.jpg" class="card-img-top" />
                    <h5 class="card-title">Победители в допноминации Emotional FusionBrain (AI Journey)</h5>
                    <p class="card-text">На основе видео на входе наша модель лучше всех ответила на вопросы по течению игры, и определила роли участников, а также правдоподобность тезисов.</p>
                    <a href="https://dsworks.ru/champ/emotional-fusionbrain#emotional-result" target="_blank" class="btn">Результаты</a>
                </div>
              </div>
          </div>

        <h2 id="team" className="secondary-heading">
            Команда
        </h2>

        <div class="row" >
              <div class="col-md-6">
              <div class="card-person shadow-sm">
                    <a href="https://www.linkedin.com/in/aleksandr-perevalov/" target="_blank"><img src="https://perevalov.com/img/0287.jpg" class="card-person-img-top" /></a>
                    <h5 class="card-person-title">Александр Перевалов</h5>
                    <p class="card-person-text">Опыт более 6 лет в Conversational AI (University of Paderborn, HTWK Leipzig). H-index: 7.</p>
                </div>
              </div>

              <div class="col-md-6">
              <div class="card-person shadow-sm">
                    <a href="https://www.linkedin.com/in/vladislav-raskoshinskii/" target="_blank"><img src="https://media.licdn.com/dms/image/v2/C5603AQG5oGfTRAfvrA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1602488507485?e=1739404800&v=beta&t=2QvaVvitu5tTvRzd1O9ldb51sGxu9-PONVETOuDOHUU" class="card-person-img-top" /></a>
                    <h5 class="card-person-title">Владислав Раскошинский</h5>
                    <p class="card-person-text">Опыт более 5 лет в production ML (Megafon, Credera (ex. Smart Digital). Kaggle Notebooks Expert.</p>
                </div>
              </div>
          </div>
        
        <div className="d-flex justify-content-center my-4">
            <a type="button" className="book-button" href="https://forms.yandex.com/u/6759e99290fa7baf6024c851/" target="_blank">Записаться в Waitlist</a>
        </div>  
    </div>
  );
}

export default Index;
