import React from 'react';
import '../assets/css/navigation.css'

const Navigation = () => {
  return (
    <nav className="navbar navbar-dark navbar-expand-lg py-3 shadow-sm sticky-top">
        <div className="container">
            <a className="navbar-brand" href="/">ZRENIE AI 👀</a>
            <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-1">
                <span className="visually-hidden">Toggle navigation</span>
                <span className="navbar-toggler-icon  "></span>
            </button>
            <div className="collapse navbar-collapse" id="navcol-1">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="#useCases">Примеры</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#howItWorks">Как работает</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#awards">Достижения</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#team">Команда</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  );
}

export default Navigation;
