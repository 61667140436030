import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from './components/Index';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Imprint from './components/Imprint';
import Privacy from './components/Privacy';
import Starfield from './components/Starfield';

function App() {
  return (
    <>
     <Starfield
        starCount={1000}
        starColor={[255, 255, 255]}
        speedFactor={0.05}
        backgroundColor="black"
      />
    <Navigation />
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
      </Routes>
    </Router>
    <Footer />
    </>
  );
}

export default App;
